<template>
    <div class="rental_submission">
        <PageTitle title="Rental Submission" bg="/images/header-form.jpg"/>
        <div class="rental_form" id="rentalForm">
            <p  id="formTitle" class="title">
                <span class="required">*</span> Mandatory Field
            </p>
            <form class="kwes-form"
                id="kwes-form"
                data-kw-redirect="/thankyou"
                action="https://kwes.io/api/foreign/forms/fQ92qAnEvHfSxv4p1IBX">
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Owner Name on Lease <span class="required">*</span>
                    </p>
                    <div class="form_block">
                        <div class="half">
                            <input type="text" name="first_name" rules="required|max:255">
                            <label class="text_label" for="first_name">First Name</label>
                        </div>
                        <div class="half">
                            <input type="text" name="last_name" rules="required|max:255">
                            <label class="text_label" for="last_name">Last Name</label>
                        </div>
                    </div>
                </div>
                <div class="form_container-left half">
                    <p class="form_title">
                        Phone Number <span class="required">*</span>
                    </p>
                    <div class="form_block">
                        <div class="full">
                            <input type="text" name="phone" rules="required|max:255" placeholder="(000) 000-0000">
                        </div>
                    </div>
                </div>
                <div class="form_container-right half">
                    <p class="form_title">
                        Email <span class="required">*</span>
                    </p>
                    <div class="form_block">
                        <div class="full">
                            <input type="email" name="email" rules="required|max:255" placeholder="example@email.com">
                        </div>
                    </div>
                </div>
                <div class="form_container">
                    <p class="form_title">
                        Property Address <span class="required">*</span>
                    </p>
                    <div class="form_block">
                        <div class="full address">
                            <input type="text" name="address_1" rules="required|max:255">
                             <label class="text_label" for="address_1">Street Address (Line 1)</label>
                        </div>
                        <div class="full address">
                            <input type="text" name="address_2" rules="max:255">
                             <label class="text_label" for="address_2">Street Address (Line 2)</label>
                        </div>
                        <div class="half">
                            <input type="text" name="city" rules="required|max:255">
                             <label class="text_label" for="city">City</label>
                        </div>
                        <div class="half">
                            <input type="text" name="state" rules="required|max:255">
                             <label class="text_label" for="state">State</label>
                        </div>
                        <div class="half">
                            <input type="text" name="zipcode" rules="required|max:255">
                             <label class="text_label" for="zipcode">Zip Code</label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Service <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="service" value="Lease Only">
                                <label for="service">Lease Only</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="service" value="Lease and Management">
                                <label for="service">Lease and Management</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Address to Remit Rent <span class="required">*</span>
                    </p>
                    <div class="form_block">
                        <div class="full address">
                            <input type="text" name="remit_rent_address_1" rules="required|max:255">
                             <label class="text_label" for="remit_rent_address_1">Street Address (Line 1)</label>
                        </div>
                        <div class="full address">
                            <input type="text" name="remit_rent_address_2" rules="max:255">
                             <label class="text_label" for="remit_rent_address_2">Street Address (Line 2)</label>
                        </div>
                        <div class="half">
                            <input type="text" name="remit_rent_city" rules="required|max:255">
                             <label class="text_label" for="remit_rent_city">City</label>
                        </div>
                        <div class="half">
                            <input type="text" name="remit_rent_state" rules="required|max:255">
                             <label class="text_label" for="remit_rent_state">State</label>
                        </div>
                        <div class="half">
                            <input type="text" name="remit_rent_zipcode" rules="required|max:255">
                             <label class="text_label" for="remit_rent_zipcode">Zip Code</label>
                        </div>
                        <div class="half">
                        </div>
                        <div class="half">
                            <input type="checkbox" name="managed_by_CZRE" value="Yes">
                            <label for="features">Managed by CZRE</label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Sign on Site <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="sign_on_site" value="Yes">
                                <label for="sign_on_site">Yes</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="sign_on_site" value="No">
                                <label for="sign_on_site">No</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Terms <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="terms" value="Short Term">
                                <label for="terms">Short Term</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="terms" value="1 Year Lease">
                                <label for="terms">1 Year Lease</label>
                            </div>
                            <div class="quater">
                            </div>
                            <div class="quater">
                            </div>
                            <div class="full flex">
                                <input type="radio" name="terms" value="Other" v-model="terms_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="terms_other">Other: </label>
                                    <input type="text" name="terms_other" :disabled="!terms_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Rental Amount <span class="required">*</span>
                    </p>
                    <div class="form_block">
                        <div class="full">
                            <input type="text" name="rental_amount" rules="required|max:255">
                            <label class="text_label" for="rental_amount">Enter Monthly Amount</label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Rent Program Fee - Pass through to tenants? <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="rental_program_fee" value="Yes" v-model="rental_program_fee">
                                <label for="rental_program_fee">Yes</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="rental_program_fee" value="No" v-model="rental_program_fee">
                                <label for="rental_program_fee">No</label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Security Deposit <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="full">
                                <input type="radio" name="deposit" value="$700 above Monthly Rent">
                                <label for="deposit">$700 above Monthly Rent</label>
                            </div>
                            <div class="full flex">
                                <input type="radio" name="deposit" value="Other" v-model="deposit_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="deposit_other">Other: </label>
                                    <input type="text" name="deposit_other" :disabled="!deposit_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Late Fee Amount <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="late_fees" value="$10 per day">
                                <label for="late_fees">$10 per day</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="late_fees" value="$20 per day">
                                <label for="late_fees">$20 per day</label>
                            </div>
                            <div class="quater">
                            </div>
                            <div class="quater">
                            </div>
                            <div class="full flex">
                                <input type="radio" name="late_fees" value="Other" v-model="late_fee_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="late_fees_other">Other: </label>
                                    <input type="text" name="late_fees_other" :disabled="!late_fee_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Who Pays Rental Fee <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="who_pays" value="Tenant(s) - Alameda Only">
                                <label for="who_pays">Tenant(s) - Alameda Only</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="who_pays" value="Split - Alameda Only">
                                <label for="who_pays">Split - Alameda Only</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="who_pays" value="Owner">
                                <label for="who_pays">Owner</label>
                            </div>
                            <div class="quater">
                            </div>
                            <div class="full flex">
                                <input type="radio" name="who_pays" value="Other" v-model="who_pays_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="who_pays">Other: </label>
                                    <input type="text" name="who_pays_other" :disabled="!who_pays_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Date Available <span class="required">*</span>
                    </p>
                    <div class="form_block">
                        <div class="full kw-datepicker-wrapper">
                            <img src="/images/calendar.svg" class="date_icon" >
                            <input type="datepicker" name="date_available" class="date_input">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        How to show: <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="how_to_show" value="Vacant">
                                <label for="how_to_show">Vacant</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="how_to_show" value="Tenant(s)">
                                <label for="how_to_show">Tenant(s)</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="how_to_show" value="24 Hour">
                                <label for="how_to_show">24 Hour</label>
                            </div>
                            <div class="quater">
                            </div>
                            <div class="full flex">
                                <input type="radio" name="how_to_show" value="Other" v-model="howtoshow_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="how_to_show_other">Other: </label>
                                    <input type="text" name="how_to_show_other" :disabled="!howtoshow_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Key: <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="key" value="At the Office">
                                <label for="key">At the office</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="key" value="On lock box">
                                <label for="key">On lock box</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="key" value="Call current resident">
                                <label for="key">Call current resident</label>
                            </div>
                            <div class="quater">
                            </div>
                            <div class="full flex">
                                <input type="radio" name="key" value="Other" v-model="key_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="key_other">Other: </label>
                                    <input type="text" name="key_other" :disabled="!key_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Occupied <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="occupied" value="Owner(s)">
                                <label for="occupied">Owner(s)</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="occupied" value="Tenant(s)">
                                <label for="occupied">Tenant(s)</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="occupied" value="Vacant">
                                <label for="occupied">Vacant</label>
                            </div>
                            <div class="quater">
                            </div>
                            <div class="full flex">
                                <input type="radio" name="occupied" value="Other" v-model="occupied_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="occupied_other">Other: </label>
                                    <input type="text" name="occupied_other" :disabled="!occupied_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        HOA <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="HOA" value="Yes" v-model="hoa">
                                <label for="HOA">Yes</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="HOA" value="No" v-model="hoa">
                                <label for="HOA">No</label>
                            </div>
                            <div class="full flex" v-show="hoa === 'Yes'">
                                <div class="other_input">
                                    <label for="HOA-name" style="width:260px">Enter HOA name & HOA number: </label>
                                    <input type="text" name="HOA_name" rules="max:255">
                                </div>
                            </div>
                            <div class="full flex" v-show="hoa === 'Yes'">
                                <div class="other_input">
                                    <label for="HOA-fee" style="width:130px">HOA MI/MO Fee: </label>
                                    <input type="text" name="HOA_fee" rules="max:255">
                                </div>
                            </div>
                            <div class="full flex" v-show="hoa === 'Yes'">
                                <div class="other_input">
                                    <label for="HOA-who_pays" style="width:80px">Who pays: </label>
                                    <input type="text" name="HOA_who_pays" rules="max:255">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        House Style <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="house_style" value="House">
                                <label for="house_style">House</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="house_style" value="Condominum">
                                <label for="house_style">Condominum</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="house_style" value="Townhome">
                                <label for="house_style">Townhome</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="house_style" value="Apartment">
                                <label for="house_style">Apartment</label>
                            </div>
                            <div class="full flex">
                                <input type="radio" name="house_style" value="Other" v-model="house_style_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="house_style">Other: </label>
                                    <input type="text" name="house_style_other" :disabled="!house_style_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container-left half">
                    <p class="form_title">
                        Beds <span class="required">*</span>
                    </p>
                    <div class="form_block">
                        <div class="full">
                            <input type="text" name="beds" rules="required|max:255">
                            <label class="text_label" for="beds">Number of Bedrooms</label>
                        </div>
                    </div>
                </div>
                <div class="form_container-right half">
                    <p class="form_title">
                        Baths <span class="required">*</span>
                    </p>
                    <div class="form_block">
                        <div class="full">
                            <input type="text" name="baths" rules="required|max:255">
                            <label class="text_label" for="baths">Number of Bathrooms</label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Features <span class="required">*</span>
                    </p>
                    <p class="label">Select one or more</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="checkbox" name="features" value="Living Room">
                                <label for="features">Living Room</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="features" value="Dining Room">
                                <label for="features">Dining Room</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="features" value="Family Room">
                                <label for="features">Family Room</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="features" value="Fireplace">
                                <label for="features">Fireplace</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="features" value="Fireplace (Decorative only)">
                                <label for="features">Fireplace (Decorative only)</label>
                            </div>
                            <div class="full flex">
                                <input type="checkbox" name="features" value="Other" v-model="features_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="features">Other: </label>
                                    <input type="text" name="features_other" :disabled="!features_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Floors <span class="required">*</span>
                    </p>
                    <p class="label">Select one or more</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="checkbox" name="floors" value="Wood">
                                <label for="floors">Wood</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="floors" value="Laminate">
                                <label for="floors">Laminate</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="floors" value="Carpet">
                                <label for="floors">Carpet</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="floors" value="Tile">
                                <label for="floors">Tile</label>
                            </div>
                            <div class="full flex">
                                <input type="checkbox" name="floors" value="Other" v-model="floors_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="floors">Other: </label>
                                    <input type="text" name="floors_other" :disabled="!floors_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Kitchen <span class="required">*</span>
                    </p>
                    <p class="label">Select one or more</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="checkbox" name="kitchen" value="Stove - Gas">
                                <label for="kitchen">Stove - Gas</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="kitchen" value="Stove - Electric">
                                <label for="kitchen">Stove - Electric</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="kitchen" value="Disposal">
                                <label for="kitchen">Disposal</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="kitchen" value="Dishwasher">
                                <label for="kitchen">Dishwasher</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="kitchen" value="Refrigerator">
                                <label for="kitchen">Refrigerator</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="kitchen" value="Counters - Stone">
                                <label for="kitchen">Counters - Stone</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="kitchen" value="Counters - Tile">
                                <label for="kitchen">Counters - Tile</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="kitchen" value="Counters - Other">
                                <label for="kitchen">Counters - Other</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="kitchen" value="Microwave">
                                <label for="kitchen">Microwave</label>
                            </div>
                            <div class="full flex">
                                <input type="checkbox" name="kitchen" value="Other" v-model="kitchen_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="kitchen_other">Other: </label>
                                    <input type="text" name="kitchen_other" :disabled="!kitchen_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Yard Type <span class="required">*</span>
                    </p>
                    <p class="label">Select one or more</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="checkbox" name="yard_type" value="None">
                                <label for="yard_type">None</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="yard_type" value="Shared">
                                <label for="yard_type">Shared</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="yard_type" value="Front">
                                <label for="yard_type">Front</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="yard_type" value="Back">
                                <label for="yard_type">Back</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="yard_type" value="Deck">
                                <label for="yard_type">Deck</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="yard_type" value="Patio">
                                <label for="yard_type">Patio</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="yard_type" value="Belcony">
                                <label for="yard_type">Belcony</label>
                            </div>
                            <div class="full flex">
                                <input type="checkbox" name="yard_type" value="Other" v-model="yard_type_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="yard_type_other">Other: </label>
                                    <input type="text" name="yard_type_other" :disabled="!yard_type_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Laundry <span class="required">*</span>
                    </p>
                    <p class="label">Select one or more</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="checkbox" name="laundry" value="Washer">
                                <label for="laundry">Washer</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="laundry" value="Dryer">
                                <label for="laundry">Dryer</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="laundry" value="Hook Ups Only">
                                <label for="laundry">Hook Ups Only</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="laundry" value="Coin">
                                <label for="laundry">Coin</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="laundry" value="Free / Shared Laundry">
                                <label for="laundry">Free / Shared Laundry</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="laundry" value="Washer and Dryer not warrantied">
                                <label for="laundry">Washer and Dryer not warrantied</label>
                            </div>
                            <div class="full flex">
                                <input type="checkbox" name="laundry" value="Other" v-model="laundry_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="laundry_other">Other: </label>
                                    <input type="text" name="laundry_other" :disabled="!laundry_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Parking <span class="required">*</span>
                    </p>
                    <p class="label">Select one or more</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="checkbox" name="parking" value="Garage">
                                <label for="kitchen">Garage</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="parking" value="Carpot">
                                <label for="kitchen">Carpot</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="parking" value="Uncovered">
                                <label for="kitchen">Uncovered</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="parking" value="On-Street">
                                <label for="kitchen">On-Street</label>
                            </div>
                            <div class="full flex">
                                <input type="checkbox" name="parking" value="Other" v-model="parking_other">
                                <div class="other_input">
                                    <label for="parking_other">Other: </label>
                                    <input type="text" name="parking_other" :disabled="!parking_other">
                                </div>
                            </div>
                            <div class="full flex">
                                <div class="other_input">
                                    <label for="parking_number_of_spaces" style="width:240px">Number of Spaces and Spot #:</label>
                                    <input type="text" name="parking_number_of_spaces" rules="max:255">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Storage <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="storage" value="Yes" v-model="storage">
                                <label for="storage">Yes</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="storage" value="No" v-model="storage">
                                <label for="storage">No</label>
                            </div>
                            <div class="full flex">
                                <input type="radio" name="storage" value="Other" v-model="storage">
                                <div class="other_input">
                                    <label for="storage_other">Other: </label>
                                    <input type="text" name="storage_other" :disabled="storage !== 'Other'">
                                </div>
                            </div>
                            <div class="full flex" v-show="storage === 'Yes'">
                                <div class="other_input">
                                    <label for="storage_location" style="width:70px">Location:</label>
                                    <input type="text" name="storage_location" rules="max:255">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Pets <span class="required">*</span>
                    </p>
                    <p class="label">Select one</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="radio" name="pets" value="Yes" v-model="pets">
                                <label for="pets">Yes</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="pets" value="No" v-model="pets">
                                <label for="pets">No</label>
                            </div>
                            <div class="quater">
                                <input type="radio" name="pets" value="Negotiable">
                                <label for="pets">Negotiable</label>
                            </div>
                            <div class="full flex">
                                <input type="radio" name="pets" value="Other" v-model="pets">
                                <div class="other_input">
                                    <label for="pets_other">Other: </label>
                                    <input type="text" name="pets_other" :disabled="pets !== 'Other'">
                                </div>
                            </div>
                            <div class="full flex" v-show="pets === 'Yes'">
                                <div class="other_input">
                                    <label for="pet_type_size" style="width:130px">Pet Type/Size:</label>
                                    <input type="text" name="pet_type_size" rules="max:255">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Utilities Paid by the Owner: <span class="required">*</span>
                    </p>
                    <p class="label">Select one or more</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="checkbox" name="paid_by_owner" value="None">
                                <label for="paid_by_owner">None</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_owner" value="Gas">
                                <label for="paid_by_owner">Gas</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_owner" value="Electric">
                                <label for="paid_by_owner">Electric</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_owner" value="Water">
                                <label for="paid_by_owner">Water</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_owner" value="Garbage">
                                <label for="paid_by_owner">Garbage</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_owner" value="Landscaping">
                                <label for="paid_by_owner">Landscaping</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_owner" value="Pest Services">
                                <label for="paid_by_owner">Pest Services</label>
                            </div>
                            <div class="full flex">
                                <input type="checkbox" name="paid_by_owner" value="Other" v-model="items_paid_by_owner_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="paid_by_owner_other">Other: </label>
                                    <input type="text" name="paid_by_owner_other" :disabled="!items_paid_by_owner_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <div class="form_container">
                    <p class="form_title">
                        Utilities Paid by the Tenant: <span class="required">*</span>
                    </p>
                    <p class="label">Select one or more</p>
                    <fieldset data-kw-group data-kw-rules="required">
                        <div class="form_block">
                            <div class="quater">
                                <input type="checkbox" name="paid_by_tenant" value="None">
                                <label for="paid_by_tenant">None</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_tenant" value="Gas">
                                <label for="paid_by_tenant">Gas</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_tenant" value="Electric">
                                <label for="paid_by_tenant">Electric</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_tenant" value="Water">
                                <label for="paid_by_tenant">Water</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_tenant" value="Garbage">
                                <label for="paid_by_tenant">Garbage</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_tenant" value="Landscaping">
                                <label for="paid_by_tenant">Landscaping</label>
                            </div>
                            <div class="quater">
                                <input type="checkbox" name="paid_by_tenant" value="Pest Services">
                                <label for="paid_by_tenant">Pest Services</label>
                            </div>
                            <div class="full flex">
                                <input type="checkbox" name="paid_by_tenant" value="Other" v-model="items_paid_by_tenant_other" style="margin-bottom:0">
                                <div class="other_input">
                                    <label for="paid_by_tenant_other">Other: </label>
                                    <input type="text" name="paid_by_tenant_other" :disabled="!items_paid_by_tenant_other">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <hr>
                <button v-bind:disabled="submitting" class="btn" type="submit">
                    <span>Submit</span>
                    <Spinner v-if="submitting" size="small" line-fg-color="#000" style="padding: 0;"/>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import PageTitle from '../components/PageTitle'
import kwesforms from 'kwesforms';
import Spinner from 'vue-simple-spinner'


export default {
    name: "RentalForm",
    components:{
        PageTitle,
        Spinner
    },
    data(){
        return{
            submitting: false,
            floors_other: '',
            features_other: '',
            howtoshow_other: '',
            key_other: '',
            kitchen_other: '',
            yard_type_other: '',
            laundry_other: '',
            parking_other: '',
            occupied_other: '',
            late_fee_other: '',
            who_pays_other: '',
            terms_other: '',
            deposit_other: '',
            house_style_other: '',
            items_paid_by_owner_other: '',
            items_paid_by_tenant_other: '',
            hoa:'',
            storage:'',
            pets: '',
            rental_program_fee: '',
        }
    },
    mounted:function(){
        kwesforms.init();
        setTimeout(() => {
            document.getElementsByTagName("small")[0].style.display = 'none'
        }, 3000);
        var form = document.getElementById('kwes-form');

        form.addEventListener('kwOnSubmit', function () {
            this.submitting = true;
        });

        form.addEventListener('kwHasErrors', function () {
            this.submitting = false;
            const el = document.getElementById('formTitle');
            if (el) {
                el.scrollIntoView(false);
            }
        });

    },
    methods:{
        scrollToElement(id) {
            const el = document.getElementById(id);
            if (el) {
                el.scrollIntoView();
            }
        },
    },
    metaInfo: {
        title: 'Rental Submission',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description',
            content: "Cerda-Zein"
        }]
    },
}
</script>

<style lang="scss" scoped>
span{
    &.required{
        color: #f2673a;
    }
}
input[type=text], input[type=email]{
    color: #000;
    border: 1px solid #ccc;
    height: 40px;
    width: 100%;
    display: block;
    padding-left: 10px;
    &::placeholder{
        font-size: 0.85rem;
    }
    font-size: 0.85rem;
    border-radius: none;
}

.full.address{
    input[type=text], input[type=email]{
        width: 100%;
    }
}

input[type=radio], input[type=checkbox]{
    margin-right: 1rem;
    margin-left: 0;
    width: 14px;
    height: 14px;
    margin-bottom: 26px;
}

input:disabled{
    background: none;
}

.date_icon{
    margin-top: 10px;
    margin-left: 10px;
    height: 20px;
    position: absolute;
    z-index: 0;
    opacity: 0.25;
}

.date_input{
    height: 40px;
    padding-left: 32px;
    background: transparent;
    font-size: 0.85rem;
}

.text_label{
    font-size: 0.75rem;
    color: #555;
}

.kwes-form{
    display: flex;
    flex-wrap: wrap;
}

.form_container{
    width: 100%;
}
.form_container-left{
    margin: 1rem 0;
    padding-left: 4rem;
    padding-right: 20px;
}
.form_container-right{
    margin: 1rem 0;
    padding-right: 4rem;
    padding-left: 20px;
}

hr{
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    border: none;
    margin: 2.5rem 0;
}

button{
    background: #fff;
    margin: 4rem auto;
    margin-bottom: 8rem;
    &:hover{
        cursor: pointer;
    }
}

label{
    vertical-align: top;
}

.full{
    width: 100% !important;
    margin-bottom: 1rem;
}

.half{
    width: 50% !important;
    margin-bottom: 1rem;
}

.quater{
    width: 25% !important;
    margin-bottom: 1rem;
}
fieldset{
    border: none;
    margin: 0;
    padding: 0;
}
.form_title{
    font-weight: 600;
}
.form_block{
    display: flex;
    flex-wrap: wrap;
}
.flex{
    display: flex;
    align-items: center;
}
.rental_form{
    margin: 0 2rem;
    .title{
        margin-top: 3.5rem;
        text-align: center;
    }
    .form_container{
        margin: 1rem 4rem;
        fieldset{
            .full, .half, .quater{
                margin-bottom: 0;
            }
            .other_input{
                display: table;
                flex: 1;
                align-self: baseline;
                label{
                    display: table-cell;
                    width: 20px;
                    vertical-align: middle;
                }
                input[type="text"]{
                    display: table-cell;
                    margin-left: 1rem;
                    width: calc(100% - 1rem);
                    border: none;
                    border-bottom: 1px solid #000;
                    height: 1rem;
                }
            }
        }
        .half:nth-child(odd){
            padding-right: 20px;
        }
        .half:nth-child(even){
            padding-left: 20px;
        }
    }
}
small{
    display: none !important;
}
.btn{
    display: flex;
    gap: 0 5px;
    align-items: center;
}

@media (max-width: 768px) { 
    .quater{
        width: 50% !important;
    }
}

@media (max-width: $mobile) {
    .rental_form {
        .form_container{
            margin: 0;
            fieldset{
                .other_input{
                    label{
                        display: table-cell;
                        width: 20px;
                        vertical-align: middle;
                    }
                    input[type="text"]{
                        height: 1.5rem;
                        border-radius: 0;
                        outline: none;
                    }
                }
            }
        }
        .form_container-left{
            margin: 0;
            padding: 0;
        }
        .form_container-right{
            margin: 0;
            padding: 0;
        }
    }
    .half{
        width: 100% !important;
        padding: 0 !important; 
    }
    .quater{
        width: 100% !important;
    }
    input[type=radio], input[type=checkbox]{
        width: 16px;
        height: 16px;
    }
}
</style>

<style lang="scss">
.kw-field-error-message{
    color: #f2673a;
}
.kw-alert-error{
    margin: 0 4rem;
}

@media (max-width: $mobile) {
    .kw-alert-error{
        margin: 0;
    }
}
</style>
